import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  Image,
  Breadcrumbs,
  BannerLinks,
  TextBox,
  BlogSlider,
  Appointment
} from '../../components'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import BannerImage from '../../assets/header.cms.vergleich.jpg'
import TakeOff from '../../assets/bannerlinks/takeoff.jpg'
import CMSse from '../../assets/cmsse.png'
import Georg from '../../assets/georg_neu.png'

import { generateBreadcrumbsLinks } from '../../utils/index'

const CMSVergleich = () => {
  const links = generateBreadcrumbsLinks('/blog/cms-vergleich', 'CMS Vergleich')
  return (
    <Layout>
      <Helmet
        title="CMS Vergleich"
        meta={[
          {
            name: 'description',
            content:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
          },
          {
            name: 'keywords',
            content:
              'CMS Vergleich holzweg, Blog holzweg, Innsbruck, holzweg, Blog, CMS Vergleich, ezpublish, eZ-Publish, eZ-Platform, Typo3, Wordpress, Vergleich, Contentmanagementsystem'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={<span>And the winner is...</span>}
      />
      <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title="CMS-Vergleich"
        text={<h2>comming soon</h2>
        }
      />
      <Appointment image={Georg} />
      <TextBox titleMods={['center']} title="Weitere Blogeinträge" text="" />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}
export default CMSVergleich
